<template>
  <form-finish />
</template>

<script>
import formFinish from '../../form/medication/Form-medication-finish.vue'

export default ({
  components: {
    formFinish,
  },
})
</script>
